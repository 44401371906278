<template>
  <div>
    <template v-if="loading">
      <div class="position-relative" style="height:400px;">
        <spinner bg-transparent />
      </div>
    </template>
    <template v-else>
      <details-header
        :applicant="applicant"
        :seller="seller"
        :application="application"
        :cooperative="cooperative"
        :loanRatio="getLoanRatio(application)"
        :debtIncomeRatio="getDebtIncomeRatio(application)"
      />
      <Details />
    </template>
    <b-modal v-model="pdfModal" centered footer-class="text-right">
      <template v-slot:modal-title> Application #{{ application.id }} </template>
      <b-card-text> PDF document has been successfully generated. </b-card-text>
      <template v-slot:modal-footer>
        <b-btn variant="primary" class="mr-2" @click="downloadApplication(application)">Download</b-btn>
        <b-btn variant="white" @click="pdfModal = false">Close</b-btn>
      </template>
    </b-modal>

    <b-modal v-model="fullPdfModal" centered footer-class="text-right">
      <template v-slot:modal-title> Application #{{ application.id }} </template>
      <b-card-text> Full PDF document has been successfully generated. </b-card-text>
      <template v-slot:modal-footer>
        <b-btn variant="primary" class="mr-2" @click="downloadFullApplication(application)">Download</b-btn>
        <b-btn variant="white" @click="fullPdfModal = false">Close</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DetailsHeader from '@/components/application/DetailsHeader';
import Details from '@/components/application/Details';
import applicationLoans from '@/mixins/application-loans';
import applicationPdf from '@/mixins/application-pdf';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ApplicationDetails',
  mixins: [applicationLoans, applicationPdf],
  components: {
    DetailsHeader,
    Details,
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      await this.getApplication(this.applicationId);

      // Haven't Generated Pdf
      if (
        !this.application.pdf_without_docs_ready &&
        !this.application.pdf_without_docs_generated_at &&
        !this.application.pdf_without_docs_in_progress
      ) {
        await this.generatePdf(this.applicationId);
        await this.startPdfChecking(this.application);
      } else if (!this.application.pdf_without_docs_ready && this.application.pdf_without_docs_in_progress) {
        // Started Generated Pdf
        await this.startPdfChecking(this.application);
      }

      // Haven't Generated Full Pdf
      if (
        !this.application.pdf_with_docs_ready &&
        !this.application.pdf_with_docs_generated_at &&
        !this.application.pdf_with_docs_in_progress
      ) {
        await this.generateFullPdf(this.applicationId);
        await this.startFullPdfChecking(this.application);
      } else if (!this.application.pdf_with_docs_ready && this.application.pdf_with_docs_in_progress) {
        // Started Generated Full Pdf
        await this.startFullPdfChecking(this.application);
      }

      await this.fetchContacts();
    } catch (error) {
      console.error('error :>> ', error);
    }

    if (
      !this.applicant.id ||
      this.application.step_status < 6 ||
      this.application.status == '' ||
      this.application.status == 'Invite Pending' ||
      this.application.status == 'In Progress'
    ) {
      this.$router.push({ name: 'applications' });
    }

    this.loading = false;
  },

  methods: {
    ...mapActions({
      getApplication: 'applications/show',
      getContacts: 'contacts/index',
    }),

    async fetchContacts() {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          application_id: this.applicationId,
        },
      };
      await this.getContacts(params);
    },
  },
  computed: {
    ...mapGetters({
      application: 'applications/item',
      contacts: 'contacts/items',
    }),
    seller() {
      const data = this.contacts.filter((c) => c.attributes.contact_type === 'Seller');
      return data.length > 0 ? data[data.length - 1] : {};
    },
    applicant() {
      const applicants = this.get(this.application, 'applicants.data', []);
      return applicants.length > 0 ? { id: applicants[0].id, ...applicants[0].attributes } : {};
    },
    cooperative() {
      const data = this.get(this.application, 'cooperative.data');
      return { ...data.attributes, id: data.id };
    },
    applicationId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss" scoped></style>
